import { Form, Formik } from "formik";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { EditForm } from "../EditForm";

import { Button, Card, Text, Input, Spacing, Tipbox } from "@otta/design";
import {
  CompanyTeamsDocument,
  CreateCompanyTeamDocument,
  UpdateCompanyTeamDocument,
  DeleteCompanyTeamDocument,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";
import { Loading } from "@otta/shared-components";

const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

function AddForm({ companyId }: { companyId: string }): React.ReactElement {
  const [run, { error, loading }] = useMutation(CreateCompanyTeamDocument);

  const onSubmit = useCallback(
    (name: string) => run({ variables: { companyId, input: { name } } }),
    [run, companyId]
  );

  return (
    <Card>
      <Spacing size={1}>
        {error && <Tipbox level="error">{error.message}</Tipbox>}
        <Formik<{ name: string }>
          onSubmit={(values, { resetForm }) => {
            onSubmit(values.name);
            resetForm();
          }}
          initialValues={{ name: "" }}
        >
          {({ handleChange, values }) => (
            <Form>
              <Row>
                <Input
                  name="name"
                  onChange={handleChange}
                  placeholder="Team name"
                  value={values.name ?? ""}
                />
                <Button type="submit" level="primary" disabled={loading}>
                  Add
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </Spacing>
    </Card>
  );
}

export function Teams({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  const { data, loading } = useQuery(CompanyTeamsDocument, {
    variables: { id: companyId },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <Spacing size={1}>
      <Text bold size={2}>
        Teams
      </Text>
      <AddForm companyId={companyId} />
      {data?.company?.teams.map((team, i) => (
        <EditForm
          mutation={UpdateCompanyTeamDocument}
          numberFields={["size"]}
          childrenFields={{
            members: ["name", "jobTitle"],
          }}
          deleteMutation={DeleteCompanyTeamDocument}
          item={team}
          key={i}
        />
      ))}
    </Spacing>
  );
}
