import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";
import { formatISO, parseISO } from "date-fns";

import {
  AddCompanySaleDocument,
  AllBundlesDocument,
  CompanySaleType,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";
import { pxToRem } from "@otta/design-tokens";
import {
  Modal,
  Text,
  Button,
  Tipbox,
  FieldWrapper,
  SelectField,
  InputField,
} from "@otta/design";

const ModalContent = styled.div`
  flex-direction: column;
  display: flex;
  gap: 1.5rem;
  padding: 1rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const InputWrapper = styled.div`
  min-width: ${pxToRem(400)};
  margin: lg 0;
  label {
    display: block;
    margin-bottom: sm;
  }
  display: flex;
  flex-direction: column;
  gap: sm;
`;

const SALE_TYPE_OPTIONS = [
  {
    label: "Purchase",
    value: CompanySaleType.Purchase,
  },
  {
    label: "Trial",
    value: CompanySaleType.Trial,
  },
];

type CompanySaleFormValues = {
  companyId: string;
  endDate?: string;
  saleType?: CompanySaleType;
};

const validationSchema = Yup.object().shape({
  companyId: Yup.string().required(),
  saleType: Yup.string().required(),
  endDate: Yup.date().when("saleType", {
    is: (saleType: string) => saleType === CompanySaleType.Trial,
    then: schema => schema.required("End date required for trials"),
    otherwise: schema => schema.nullable(),
  }),
});

export function CompanyEBSalesModal() {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(AllBundlesDocument);
  const [addCompanySale, { loading, error }] = useMutation(
    AddCompanySaleDocument
  );

  const handleSubmit = useCallback(
    (formValues: CompanySaleFormValues) => {
      const bundleId = data?.allBundles?.find(
        b => b.name === "employer_branding_pilot"
      )?.id;

      if (formValues.saleType) {
        addCompanySale({
          variables: {
            companyId: formValues.companyId,
            sale: {
              bundleId: bundleId ?? "",
              type: formValues.saleType,
              validUntil: formValues.endDate
                ? formatISO(parseISO(formValues.endDate))
                : undefined,
              regions: undefined,
            },
            bundleNames: ["employer_branding_pilot"],
          },
          onCompleted: () => navigate("../"),
        });
      }
    },
    [data, addCompanySale, navigate]
  );

  const form = useFormik({
    initialValues: {
      companyId: companyId ?? "",
      saleType: undefined,
      endDate: undefined,
    },
    validationSchema,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  if (!companyId) {
    return (
      <Modal open onOpenChange={() => navigate("../")}>
        <Text>No company id field</Text>
      </Modal>
    );
  }

  return (
    <Modal open onOpenChange={() => navigate("../")}>
      <ModalContent>
        <Text as={"h1"} bold size={2} align="center">
          Add Employer Branding to company
        </Text>
        {error && <Tipbox level="error">{error.message}</Tipbox>}
        <form onSubmit={form.handleSubmit}>
          <FieldWrapper
            label="Sale type"
            fieldError={
              form.touched.saleType ? form.errors.saleType : undefined
            }
            required
          >
            {({ field }) => (
              <InputWrapper>
                <SelectField
                  inputId="saleType"
                  aria-label="Sale type"
                  name="saleType"
                  onBlur={form.handleBlur("saleType")}
                  styles={{
                    container: provided => ({
                      ...provided,
                      textAlign: "left",
                    }),
                  }}
                  onChange={e => {
                    if (e) {
                      form.setFieldValue("saleType", e.value);
                    }
                  }}
                  value={SALE_TYPE_OPTIONS.find(
                    o => o.value === form.values.saleType
                  )}
                  options={SALE_TYPE_OPTIONS}
                  {...field}
                />
              </InputWrapper>
            )}
          </FieldWrapper>
          <FieldWrapper
            label="End date"
            fieldError={form.touched.endDate ? form.errors.endDate : undefined}
          >
            {({ field }) => (
              <InputWrapper>
                <InputField
                  aria-label="End date"
                  name="endDate"
                  type="date"
                  onBlur={form.handleBlur("endDate")}
                  onChange={form.handleChange("endDate")}
                  {...field}
                />
              </InputWrapper>
            )}
          </FieldWrapper>
          <StyledButton level="primary" type="submit" disabled={loading}>
            Add sale
          </StyledButton>
        </form>
      </ModalContent>
    </Modal>
  );
}
