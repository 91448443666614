import styled from "@xstyled/styled-components";
import { CSSProperties } from "react";

import { palette, pxToRem } from "@otta/design-tokens";

const Wrapper = styled.label<{ fontSize: number }>`
  display: block;
  min-height: 24px;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: ${({ fontSize = 14 }) => pxToRem(fontSize)};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;

  :has(:disabled) {
    cursor: not-allowed;
    color: gray-200;
  }

  :has(:focus-visible) {
    span {
      border: 2px solid ${palette.brand.black};
    }
  }

  input ~ span {
    background-color: gray-200;
    border: 2px solid transparent;
  }

  input:checked ~ span {
    background-color: yellow-500;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-color: black;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Checkmark = styled.span<{ noLabel: boolean }>`
  position: absolute;
  top: ${({ noLabel }) => (noLabel ? "-4px" : "50%")};
  left: ${({ noLabel }) => (noLabel ? "0" : "50%")};
  transform: ${({ noLabel }) =>
    noLabel ? "" : "translateX(-50%) translateY(-50%)"};
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: gray-100;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export function Checkbox({
  label,
  fontSize,
  className,
  labelStyle,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  fontSize?: number;
  labelStyle?: CSSProperties;
}): React.ReactElement {
  return (
    <Wrapper fontSize={fontSize ?? 14} className={className} style={labelStyle}>
      {label}
      <Input tabIndex={0} type="checkbox" {...props} />
      <Checkmark noLabel={label.length > 0} />
    </Wrapper>
  );
}
