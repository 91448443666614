import { useState, useCallback, useMemo, useEffect } from "react";

import { Text, Spacing, SelectField, ErrorText } from "@otta/design";
import { CompanyScraperType as ScraperType } from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";
import { Input } from "@toolbox/components/Input";
import { LinkFieldWrapper } from "@toolbox/components/Field/LinkField";

const SCRAPER_TYPE_OPTIONS = [
  { label: "Manual", value: "MANUAL" },
  { label: "Scraped", value: "SCRAPED" },
  { label: "Kombo", value: "KOMBO" },
];

const validate = ({ url, type }: CompanyScraperType) => {
  if (url && !type) {
    return false;
  }

  return true;
};

interface CompanyScraperType {
  url: string;
  type: ScraperType | null;
}

interface ScraperUrlComponentProps extends CompanyScraperType {
  companyId: string;
  updateCompany: (input: CompanyScraperType) => void;
  disabled: boolean;
}

export function ScraperUrl({
  companyId,
  url,
  type,
  updateCompany,
  disabled = false,
}: ScraperUrlComponentProps): React.ReactElement {
  const [firstRender, setFirstRender] = useState(true);
  const [currentScraper, setCurrentScraper] = useState<CompanyScraperType>({
    url,
    type,
  });

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const valid = useMemo(() => validate(currentScraper), [currentScraper]);

  const handleUrlChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(e => {
    if (e.target.value === "") {
      setCurrentScraper({ url: "", type: null });
    } else {
      setCurrentScraper(prevState => ({
        url: e.target.value,
        type: prevState.type,
      }));
    }
  }, []);

  const handleUpdateType = (newType: string | null) => {
    setCurrentScraper(prevState => ({
      url: prevState.url,
      type: newType as ScraperType,
    }));
  };

  const handleUpdateCompany = useCallback(() => {
    if (valid) {
      updateCompany(currentScraper);
    }
  }, [currentScraper, updateCompany, valid]);

  useEffect(() => {
    if (!firstRender) {
      handleUpdateCompany();
    }
  }, [currentScraper.type]);

  return (
    <Spacing size={-4}>
      <Text size={-1} bold>
        Scraper URL
      </Text>
      <LinkFieldWrapper>
        <Input
          data-testid="scraper-url-input"
          value={currentScraper.url}
          onChange={handleUrlChange}
          onBlur={handleUpdateCompany}
          id={companyId}
          disabled={disabled}
        />
        {currentScraper.url && (
          <Link to={currentScraper.url} underline={false}>
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </Link>
        )}
      </LinkFieldWrapper>
      {currentScraper.url && (
        <Spacing size={-4}>
          <Text size={-1} bold>
            Scraper Type
          </Text>
          <SelectField
            value={SCRAPER_TYPE_OPTIONS.find(
              o => o.value === currentScraper.type
            )}
            options={SCRAPER_TYPE_OPTIONS}
            placeholder="Select scraper type..."
            aria-label="scraper-type-select-field"
            isClearable
            onChange={newType => {
              if (newType) {
                handleUpdateType(newType.value);
              } else {
                handleUpdateType(null);
              }
            }}
          />
          {!valid && (
            <ErrorText>
              Scraper Type cannot be undefined when Scraper URL is defined
            </ErrorText>
          )}
        </Spacing>
      )}
    </Spacing>
  );
}
