import { useCallback, useState, useEffect } from "react";
import styled from "@xstyled/styled-components";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

import { FieldWrapper } from "../FieldWrapper";
import { AutoSetContainer } from "../FieldLabel";

import { ReorderableBulletItem } from "./ReorderableBulletItem";

import { Text } from "@otta/design";
import { splitBullets } from "@otta/shared-components";
import { Textarea } from "@toolbox/components/Input/Textarea";
import { Card } from "@toolbox/components/Card";

export const getHighestOrder = (items: IItem[]) =>
  items.reduce((acc, { order }) => (order ?? 0 > acc ? order ?? acc : acc), 0);

const StyledCard = styled(Card)`
  margin: 0;
`;

interface IItem {
  id: string;
  value?: string | null;
  order?: number | null;
}

interface IFieldProps {
  label: string;
  parentId: string;
  data: IItem[];
  handleUpdate: (
    field: { value: string | null; order: number | null }[]
  ) => Promise<void>;
  autoSet?: boolean;
}

export function ReorderableBulletPointField({
  label,
  data,
  handleUpdate,
  parentId,
  autoSet,
}: IFieldProps): React.ReactElement {
  const [newValue, setNewValue] = useState("");
  const [items, setItems] = useState<IItem[]>(data);

  useEffect(() => setItems(data), [data]);

  const updateItems = async (newItems: IItem[]) => {
    await handleUpdate(
      newItems
        .filter(({ value, order }) => value && typeof order === "number")
        .map(({ value, order }): { value: string; order: number } => ({
          value: value as string,
          order: order as number,
        }))
    );
    setItems(newItems);
  };

  const onCreate = async () => {
    if (newValue !== "") {
      const highestOrder = getHighestOrder(items);

      await updateItems([
        ...items,
        {
          value: newValue,
          order: highestOrder + 1,
          id: parentId,
        },
      ]);

      setNewValue("");
    }
  };

  const onUpdate = async ({
    id,
    value,
    order,
  }: {
    id: string;
    value: string;
    order: number;
  }) => {
    await updateItems(
      items.map(item => {
        if (item.id === id) {
          return { ...item, value: value, order: order };
        }

        return item;
      })
    );
  };

  const onPaste = useCallback<React.ClipboardEventHandler<HTMLTextAreaElement>>(
    async e => {
      e.preventDefault();

      const pastedData = e.clipboardData.getData("text");
      const pastedItems = splitBullets(pastedData).map(item => ({
        value: item,
        id: parentId,
      }));

      const newItems = items
        .concat(pastedItems)
        .map((item, index) => ({
          ...item,
          order: index,
        }))
        .filter((item, index, items) => {
          return items.findIndex(i => i.value === item.value) === index;
        });
      await updateItems(newItems);
    },
    [parentId, items]
  );

  const onDelete = async (id: string) => {
    await updateItems(items.filter(item => item.id !== id));
  };

  const reorder = (list: IItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    const updatedResult = result.map((item, index) => ({
      value: item.value as string,
      order: index,
      id: item.id,
    }));

    return updatedResult;
  };

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      await updateItems(
        reorder(items, result.source.index, result.destination.index)
      );
    },
    [items]
  );

  return (
    <div>
      <Text bold size={-1} style={{ display: "inline-block" }}>
        {label}
      </Text>{" "}
      {autoSet && <AutoSetContainer>Auto-set</AutoSetContainer>}
      <button
        tabIndex={0}
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          fontSize: "14px",
          border: "none",
        }}
        data-testid="delete-all-button"
        onClick={() => updateItems([])}
      >
        (Delete all)
      </button>
      <StyledCard>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div
                data-testid={`reorderable-bullets`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString() ?? ""}
                    index={index}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ReorderableBulletItem
                          key={item.id}
                          id={item.id}
                          value={item.value ?? ""}
                          order={item.order ?? 1}
                          handleUpdate={onUpdate}
                          handleDelete={onDelete}
                          data-testid="individual-reorderable-bullet-item"
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <FieldWrapper>
          <Textarea
            margin={false}
            placeholder="New item"
            data-testid="new-item-field"
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            onPaste={onPaste}
            onBlur={onCreate}
          />
        </FieldWrapper>
      </StyledCard>
    </div>
  );
}
