import { useMutation } from "@apollo/client";
import deepEqual from "deep-equal";
import { useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";

import { JobsAtSameCompany } from "./JobsAtSameCompany";
import { SalaryRange } from "./SalaryRange";
import { TeamSelect } from "./TeamSelect";

import {
  Button,
  ErrorText,
  Label,
  Spacing,
  Text,
  SelectField as SimpleSelect,
} from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import {
  Mutation,
  disapprovalReasonOptions,
  media,
  validExperience,
} from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { AdminsOnly } from "@toolbox/components/AdminsOnly";
import { CheckboxField } from "@toolbox/components/Field/CheckboxField";
import {
  CreateNewJobTextItemField,
  FieldWithButton,
} from "@toolbox/components/Field/CreateNewTextItemField";
import { FieldLabel } from "@toolbox/components/Field/FieldLabel";
import { JobRequirementsField } from "@toolbox/components/Field/JobRequirementsField";
import { LocationPreferencesField } from "@toolbox/components/Field/LocationPreferencesField";
import { MultiSelectField } from "@toolbox/components/Field/MultiSelectField";
import { ReorderableBulletPointField } from "@toolbox/components/Field/ReorderableBulletPointField";
import { RequiredOfficeDaysField } from "@toolbox/components/Field/RequiredOfficeDaysField";
import { SelectField } from "@toolbox/components/Field/SelectField";
import { TextField } from "@toolbox/components/Field/TextField";
import { TextareaField } from "@toolbox/components/Field/TextareaField";
import { PlusIcon } from "@toolbox/components/Icons/PlusIcon";
import { Link } from "@toolbox/components/Link";
import { ChangesTable } from "@toolbox/components/Table/ChangesTable";
import { tableHeadings } from "@toolbox/constants/changeTableHeadings";
import {
  CompanyTeam,
  CreateLanguageRequirementDocument,
  CreateTechnologyUsedDocument,
  JobApplicationProcessBulletInput,
  JobChangesFragment,
  JobCompanyTeamMember,
  JobDocument,
  JobFunctionsDocument,
  JobInvolvesBulletInput,
  JobLanguageRequirementInput,
  JobRequirementInput,
  JobSubFunctionsDocument,
  JobTechnologyUsedInput,
  JobWorkflowStatus,
  JobsFunctionDocument,
  JobsSubFunctionDocument,
  LanguageRequirementsDocument,
  TechnologiesUsedDocument,
  UpdateJobDocument,
  UpdateJobFunctionDocument,
  UpdateJobOriginalUrlDocument,
  UpdateJobSubFunctionDocument,
  UpdateJobSubtitleDocument,
  UpdateJobTitleDocument,
  JobCompanyTeamMembersInput,
} from "@toolbox/schema";
import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import { ExperienceSlider } from "@toolbox/components/ExperienceRangeSlider";
import { ProductBubbles } from "@toolbox/components/ProductBubbles";
import { PlanCallout } from "@toolbox/components/PlanCallout";

const GreyButton = styled(Button)`
  flex-grow: 0;
  font-size: ${modularScale(-2)};
  font-weight: normal;
  background: ${palette.grayscale.shade400};
  &:hover {
    background: ${palette.grayscale.shade200};
  }
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: xs;
  align-items: center;
`;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

const StyledTextareaField = styled(TextareaField)`
  resize: vertical;
`;

const Separator = styled.div`
  border-top: 1px solid ${palette.grayscale.shade400};
  width: 100%;
  margin-top: sm;
  margin-bottom: sm;
`;

const CompanyLinks = styled.div`
  display: flex;
  text-decoration: underline;
  column-gap: 0.875rem;
`;

const JobIframe = styled.iframe`
  overflow-y: auto;
  display: none;
  width: 100%;
  height: 100%;
  ${media.desktop} {
    display: block;
  }
`;

const JobWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  ${media.desktop} {
    max-width: 700px;
  }
`;

const InnerContent = styled.div`
  padding: 0 16px;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: beige-200;
`;

const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.875rem;
  row-gap: 0.5rem;
`;

const ExperienceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.875rem;
`;

const WorkExperienceWrapper = styled.div`
  display: flex;
  margin: 1rem 2rem 2rem;
`;

const getAutoSetFields = (changes: JobChangesFragment[]) => {
  const autoSetFields = new Set<string>();
  for (const change of changes) {
    if (change.changedBy !== "USER") {
      autoSetFields.add(change.fieldName);
    } else if (autoSetFields.has(change.fieldName)) {
      autoSetFields.delete(change.fieldName);
    }
  }
  return autoSetFields;
};

const getAutoSetLocations = (changes: JobChangesFragment[]) => {
  const autoSetLocations: Set<string> = new Set();
  for (const change of changes) {
    if (
      change.changedBy !== "USER" &&
      change.oldValue == null &&
      change.fieldName === "location_preferences" &&
      change.newValue?.__typename === "StringListValue"
    ) {
      change?.newValue.values.forEach((location: string) =>
        autoSetLocations.add(location.toUpperCase())
      );
    }
  }
  return autoSetLocations;
};

const capitaliseFirstLetter = (value: string | null): string | null => {
  if (value === null) {
    return null;
  }
  const [firstLetter, ...restOfWord] = value;

  return firstLetter.toUpperCase() + restOfWord.join("");
};

const notEmpty = (value: string | number | null) => {
  return typeof value === "string" && value.length > 0;
};

interface IJobPageProps {
  jobId: string;
}

export function JobPage({ jobId }: IJobPageProps): React.ReactElement | null {
  const [officeDaysFieldAdded, setOfficeDaysFieldAdded] = useState(false);
  const [officeLocationsFieldAdded, setOfficeLocationsFieldAdded] =
    useState(false);
  const [locationRestrictionsFieldAdded, setLocationRestrictionsFieldAdded] =
    useState(false);
  const [otherLocationInfoFieldAdded, setOtherLocationInfoFieldAdded] =
    useState(false);
  const [editOriginalUrl, setEditOriginalUrl] = useState(false);

  const { data, error } = useQuery(JobDocument, {
    variables: { id: jobId },
  });

  const [updateJobMutation] = useMutation(UpdateJobDocument);

  const { role } = useUser();
  const isAdmin = role === CurrentRoleType.Admin;

  useEffect(() => {
    if (data?.job?.minDaysInOffice || data?.job?.minDaysInOffice === 0) {
      setOfficeDaysFieldAdded(true);
    }

    if (data?.job?.officeLocation) {
      setOfficeLocationsFieldAdded(true);
    }

    if (data?.job?.locationRestrictions) {
      setLocationRestrictionsFieldAdded(true);
    }

    if (data?.job?.locationInformation) {
      setOtherLocationInfoFieldAdded(true);
    }
  }, [data?.job]);

  if (error) {
    return <JobWrapper>A job with this ID doesn&apos;t exist.</JobWrapper>;
  }

  if (!data?.job) {
    return <Wrapper />;
  }

  const {
    id,
    externalId,
    title,
    subtitle,
    company,
    originalUrl,
    previewUrl,
    actualOriginalUrl,
    editedTitle,
    otherCompensationInformation,
    workflowStatus,
    workflowNotes,
    internship,
    minYearsExperienceRequired,
    maxYearsExperienceRequired,
    locationPreferences,
    scrapedLocation,
    technologiesUsed,
    languageRequirements,
    changes,
    lastWorkflowStatusChangeBy,
    salaryRange,
    oteSalaryRange,
    requirements,
    involvesBullets,
    applicationProcessBullets,
    minDaysInOffice,
    maxDaysInOffice,
    officeLocation,
    locationRestrictions,
    locationInformation,
    team,
    teamMembers,
    disapprovalReason,
  } = data.job;

  const { isCurrentPayingCustomer, currentCustomerPlans } = data.job.company;

  const autoSetFields = getAutoSetFields(changes);
  const autoSetLocations = getAutoSetLocations(changes);
  const showJobChangedSection =
    workflowStatus === JobWorkflowStatus.Changed ||
    (workflowStatus === JobWorkflowStatus.QaExternal && isAdmin);

  const handleOfficeDaysSubmit = ({
    requiredOfficeDaysRange,
  }: {
    requiredOfficeDaysRange: {
      minDaysInOffice: string | null | undefined;
      maxDaysInOffice: string | null | undefined;
    };
  }) => {
    if (requiredOfficeDaysRange) {
      updateJobMutation({
        variables: {
          id,
          input: {
            minDaysInOffice: requiredOfficeDaysRange.minDaysInOffice
              ? parseInt(requiredOfficeDaysRange.minDaysInOffice)
              : null,
            maxDaysInOffice: requiredOfficeDaysRange.maxDaysInOffice
              ? parseInt(requiredOfficeDaysRange.maxDaysInOffice)
              : null,
          },
        },
      });
    }
  };

  const handleClearField = (fieldName: string) => {
    updateJobMutation({
      variables: {
        id,
        input: {
          [fieldName]: null,
        },
      },
    });
  };

  const handleExperienceSubmit = ({
    min,
    max,
  }: {
    min: number | null | undefined;
    max: number | null | undefined;
  }) => {
    const validValues =
      min !== null && min !== undefined && max !== null && max !== undefined;
    if (validValues) {
      updateJobMutation({
        variables: {
          id,
          input: {
            minYearsExperienceRequired: +min,
            maxYearsExperienceRequired: +max,
          },
        },
      });
    }
  };

  return (
    <Wrapper>
      <Container>
        <JobWrapper>
          <InnerContent>
            <Spacing size={-5}>
              {workflowStatus !== JobWorkflowStatus.Exception && (
                <StyledTextareaField
                  label="Notes"
                  value={workflowNotes}
                  mutation={UpdateJobDocument}
                  fieldName="workflowNotes"
                  id={id}
                  data-testid="workflow-notes"
                />
              )}
              <HeaderWrapper>
                <Text size={1} bold>
                  {title}
                </Text>
                {[
                  JobWorkflowStatus.QaExternal,
                  JobWorkflowStatus.QaInternal,
                  JobWorkflowStatus.Exception,
                ].includes(workflowStatus) &&
                  lastWorkflowStatusChangeBy && (
                    <Text size={-1}>
                      Last status change by:{" "}
                      {lastWorkflowStatusChangeBy.firstName}{" "}
                      {lastWorkflowStatusChangeBy.lastName}
                    </Text>
                  )}
                <CompanyLinks>
                  <Link to={`/companies/completed/${company.id}`}>
                    <div style={{ display: "flex" }}>
                      <Text size={-1} bold>
                        {company.name}
                      </Text>
                      <ProductBubbles company={company} />
                    </div>
                  </Link>
                  {originalUrl && (
                    <Link to={originalUrl}>
                      <Text size={-1} bold>
                        Original Url
                      </Text>
                    </Link>
                  )}
                  <Link to={`${import.meta.env.VITE_URL}/jobs/${externalId}`}>
                    <Text size={-1} bold>
                      WTTJ Url
                    </Text>
                  </Link>
                </CompanyLinks>
              </HeaderWrapper>
              {isCurrentPayingCustomer &&
                currentCustomerPlans &&
                currentCustomerPlans?.length > 0 && (
                  <PlanCallout currentCustomerPlans={currentCustomerPlans} />
                )}
              {showJobChangedSection && (
                <Spacing size={-6}>
                  <Text size={-1} bold>
                    Changes
                  </Text>
                  <ChangesTable
                    headings={tableHeadings}
                    changes={changes}
                    data-testid="job-changes-table"
                  />
                </Spacing>
              )}

              <ColumnWrapper>
                <Spacing size={-30}>
                  <TextField
                    validate={notEmpty}
                    label="Job title (edited)"
                    placeholder={editedTitle === null ? title : ""}
                    autoSet={autoSetFields.has("edited_title")}
                    value={editedTitle}
                    mutation={UpdateJobTitleDocument}
                    fieldName="editedTitle"
                    id={id}
                    data-testid="job-title"
                  />
                  <TextField
                    label="Job subtitle"
                    value={subtitle}
                    autoSet={autoSetFields.has("subtitle")}
                    mutation={UpdateJobSubtitleDocument}
                    fieldName="subtitle"
                    id={id}
                    data-testid="job-subtitle"
                  />
                  <ExperienceWrapper>
                    <SelectField
                      autoSet={autoSetFields.has("function_id")}
                      label="Function"
                      optionsQuery={JobFunctionsDocument}
                      optionsName="jobFunctions"
                      parentName="job"
                      fieldName="function"
                      createMutation={UpdateJobFunctionDocument}
                      parentId={id}
                      valueQuery={JobsFunctionDocument}
                      refetchQueries={() => [
                        { query: JobsSubFunctionDocument, variables: { id } },
                        { query: JobSubFunctionsDocument, variables: { id } },
                        { query: JobDocument, variables: { id } },
                      ]}
                      allowCreate={isAdmin}
                    />
                    <SelectField
                      label="Sub-function"
                      autoSet={autoSetFields.has("sub_function_id")}
                      optionsQuery={JobSubFunctionsDocument}
                      optionsName="jobSubFunctions"
                      parentName="job"
                      fieldName="subFunction"
                      createMutation={UpdateJobSubFunctionDocument}
                      parentId={id}
                      valueQuery={JobsSubFunctionDocument}
                      allowCreate={isAdmin}
                      refetchQueries={() => [
                        { query: JobDocument, variables: { id } },
                      ]}
                    />
                  </ExperienceWrapper>
                  <CheckboxField
                    label="Internship"
                    autoSet={autoSetFields.has("internship")}
                    value={internship}
                    mutation={UpdateJobDocument}
                    fieldName="internship"
                    id={id}
                  />
                  <Mutation mutation={UpdateJobDocument}>
                    {mutation => (
                      <LocationPreferencesField
                        scrapedLocation={scrapedLocation}
                        label="Role location"
                        autoSet={autoSetFields.has("location_preferences")}
                        autoSetLocations={autoSetLocations}
                        locationPreferences={locationPreferences}
                        handleUpdate={async newLocationPreferences => {
                          await mutation({
                            variables: {
                              id,
                              input: {
                                locationPreferences: newLocationPreferences,
                              },
                            },
                          });
                        }}
                      />
                    )}
                  </Mutation>
                  <Separator />
                  <Label>Additional Location information</Label>
                  {officeDaysFieldAdded ? (
                    <FinalForm<{
                      requiredOfficeDaysRange: {
                        minDaysInOffice: string;
                        maxDaysInOffice: string;
                      };
                    }>
                      initialValues={{
                        requiredOfficeDaysRange: {
                          minDaysInOffice:
                            minDaysInOffice !== undefined &&
                            minDaysInOffice !== null
                              ? minDaysInOffice.toString()
                              : "",
                          maxDaysInOffice: maxDaysInOffice
                            ? maxDaysInOffice.toString()
                            : "",
                        },
                      }}
                      initialValuesEqual={deepEqual}
                      onSubmit={values => handleOfficeDaysSubmit({ ...values })}
                    >
                      {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <RequiredOfficeDaysField
                            label="Days per week in office"
                            autoSet={
                              autoSetFields.has("min_days_in_office") ||
                              autoSetFields.has("max_days_in_office")
                            }
                            subText={
                              <>
                                Prefilled from company profile - make sure
                                it&apos;s correct for this job. <br />
                                This won&apos;t appear on jobs tagged remote.
                              </>
                            }
                            onBlur={() => handleSubmit()}
                            onRemove={() => {
                              handleOfficeDaysSubmit({
                                requiredOfficeDaysRange: {
                                  minDaysInOffice: null,
                                  maxDaysInOffice: null,
                                },
                              });
                              setOfficeDaysFieldAdded(false);
                            }}
                          />
                        </form>
                      )}
                    </FinalForm>
                  ) : (
                    <GreyButton
                      level="secondary"
                      size="small"
                      onClick={() => setOfficeDaysFieldAdded(true)}
                      data-testid="add-days-in-office-button"
                    >
                      <IconContainer>
                        <PlusIcon />
                      </IconContainer>
                      Days in office
                    </GreyButton>
                  )}

                  {officeLocationsFieldAdded ? (
                    <FieldLabel
                      text="Office location (if not covered above)"
                      autoSet={autoSetFields.has("office_location")}
                    >
                      <StyledTextareaField
                        label=""
                        value={officeLocation}
                        mutation={UpdateJobDocument}
                        fieldName="officeLocation"
                        id={id}
                        data-testid="office-location"
                      />
                      <GreyButton
                        level="secondary"
                        size="small"
                        onClick={() => {
                          handleClearField("officeLocation");
                          setOfficeLocationsFieldAdded(false);
                        }}
                        data-testid="clear-office-location-button"
                      >
                        Remove
                      </GreyButton>
                    </FieldLabel>
                  ) : (
                    <GreyButton
                      level="secondary"
                      size="small"
                      onClick={() => setOfficeLocationsFieldAdded(true)}
                      data-testid="add-office-locations-button"
                    >
                      <IconContainer>
                        <PlusIcon />
                      </IconContainer>
                      Office locations
                    </GreyButton>
                  )}
                  {locationRestrictionsFieldAdded ? (
                    <FieldLabel
                      text="Timezone/location restrictions"
                      autoSet={autoSetFields.has("location_restrictions")}
                    >
                      <StyledTextareaField
                        label=""
                        value={locationRestrictions}
                        mutation={UpdateJobDocument}
                        fieldName="locationRestrictions"
                        id={id}
                        data-testid="location-restrictions"
                        transform={capitaliseFirstLetter}
                      />
                      <GreyButton
                        level="secondary"
                        size="small"
                        onClick={() => {
                          handleClearField("locationRestrictions");
                          setLocationRestrictionsFieldAdded(false);
                        }}
                        data-testid="clear-location-restrictions-button"
                      >
                        Remove
                      </GreyButton>
                    </FieldLabel>
                  ) : (
                    <GreyButton
                      level="secondary"
                      size="small"
                      onClick={() => setLocationRestrictionsFieldAdded(true)}
                      data-testid="add-location-restrictions-button"
                    >
                      <IconContainer>
                        <PlusIcon />
                      </IconContainer>
                      Location restrictions
                    </GreyButton>
                  )}
                  {otherLocationInfoFieldAdded ? (
                    <FieldLabel
                      text="Other location information"
                      autoSet={autoSetFields.has("location_information")}
                    >
                      <StyledTextareaField
                        label=""
                        value={locationInformation}
                        mutation={UpdateJobDocument}
                        fieldName="locationInformation"
                        id={id}
                        data-testid="other-location-info"
                        transform={capitaliseFirstLetter}
                      />
                      <GreyButton
                        level="secondary"
                        size="small"
                        onClick={() => {
                          handleClearField("locationInformation");
                          setOtherLocationInfoFieldAdded(false);
                        }}
                        data-testid="clear-other-location-info-button"
                      >
                        Remove
                      </GreyButton>
                    </FieldLabel>
                  ) : (
                    <GreyButton
                      level="secondary"
                      size="small"
                      onClick={() => setOtherLocationInfoFieldAdded(true)}
                      data-testid="add-other-location-info-button"
                    >
                      <IconContainer>
                        <PlusIcon />
                      </IconContainer>
                      Other location info
                    </GreyButton>
                  )}
                  <Separator />
                  <FinalForm<{
                    min: number | null | undefined;
                    max: number | null | undefined;
                  }>
                    initialValues={{
                      min:
                        typeof minYearsExperienceRequired === "number"
                          ? minYearsExperienceRequired
                          : null,
                      max:
                        typeof maxYearsExperienceRequired === "number"
                          ? maxYearsExperienceRequired
                          : null,
                    }}
                    initialValuesEqual={deepEqual}
                    onSubmit={values => handleExperienceSubmit({ ...values })}
                    validate={values => {
                      const error = validExperience(values.min, values.max);
                      if (error) {
                        return { min: error };
                      }
                      return;
                    }}
                  >
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <Field name="min">
                          {({ input: minInput, meta }) => (
                            <FieldLabel
                              text="Experience level"
                              autoSet={
                                autoSetFields.has(
                                  "min_years_experience_required"
                                ) ||
                                autoSetFields.has(
                                  "max_years_experience_required"
                                )
                              }
                            >
                              <Field name="max">
                                {({ input: maxInput }) => (
                                  <WorkExperienceWrapper>
                                    <ExperienceSlider
                                      value={[minInput.value, maxInput.value]}
                                      onChange={value => {
                                        minInput.onChange(value[0]);
                                        maxInput.onChange(value[1]);
                                      }}
                                      onAfterChange={() => handleSubmit()}
                                      disabled={!!internship}
                                    />
                                  </WorkExperienceWrapper>
                                )}
                              </Field>

                              {meta.error && meta.touched && (
                                <ErrorText>{meta.error}</ErrorText>
                              )}
                            </FieldLabel>
                          )}
                        </Field>
                      </form>
                    )}
                  </FinalForm>

                  <MultiSelectField
                    label="Technologies used"
                    optionsQuery={TechnologiesUsedDocument}
                    fieldName="technologiesUsed"
                    data={technologiesUsed}
                    handleUpdate={async technologiesUsed => {
                      await updateJobMutation({
                        variables: {
                          id,
                          input: {
                            technologiesUsed:
                              technologiesUsed as JobTechnologyUsedInput[],
                          },
                        },
                      });
                    }}
                  />

                  <MultiSelectField
                    label="Language requirements"
                    optionsQuery={LanguageRequirementsDocument}
                    fieldName="languageRequirements"
                    data={languageRequirements}
                    handleUpdate={async languageRequirements => {
                      await updateJobMutation({
                        variables: {
                          id,
                          input: {
                            languageRequirements:
                              languageRequirements as JobLanguageRequirementInput[],
                          },
                        },
                      });
                    }}
                  />

                  <JobRequirementsField
                    label="Who you are"
                    parentId={id}
                    data={requirements}
                    autoSet={autoSetFields.has("requirements")}
                    handleUpdate={async requirements => {
                      await updateJobMutation({
                        variables: {
                          id,
                          input: {
                            requirements: requirements as JobRequirementInput[],
                          },
                        },
                      });
                    }}
                  />

                  <ReorderableBulletPointField
                    label="What the job involves"
                    parentId={id}
                    data={involvesBullets}
                    autoSet={autoSetFields.has("involves_bullets")}
                    handleUpdate={async involvesBullets => {
                      await updateJobMutation({
                        variables: {
                          id,
                          input: {
                            involvesBullets:
                              involvesBullets as JobInvolvesBulletInput[],
                          },
                        },
                      });
                    }}
                  />
                  <ReorderableBulletPointField
                    label="Application process"
                    parentId={id}
                    data={applicationProcessBullets}
                    autoSet={autoSetFields.has("application_process_bullets")}
                    handleUpdate={async applicationProcessBullets => {
                      await updateJobMutation({
                        variables: {
                          id,
                          input: {
                            applicationProcessBullets:
                              applicationProcessBullets as JobApplicationProcessBulletInput[],
                          },
                        },
                      });
                    }}
                  />
                  <Separator />
                  <SalaryRange
                    range={salaryRange}
                    oteRange={oteSalaryRange}
                    currency={
                      salaryRange?.currency ?? oteSalaryRange?.currency ?? null
                    }
                    jobId={jobId}
                    autoSetFields={autoSetFields}
                  />

                  <StyledTextareaField
                    label="Other compensation information"
                    value={otherCompensationInformation}
                    mutation={UpdateJobDocument}
                    fieldName="otherCompensationInformation"
                    id={id}
                    data-testid="other-compensation-information"
                    transform={capitaliseFirstLetter}
                  />
                  <Separator />

                  <Label htmlFor="disapprovalReason">Disapproval reason</Label>
                  <SimpleSelect<string>
                    inputId="disapprovalReason"
                    options={disapprovalReasonOptions}
                    value={disapprovalReasonOptions.find(
                      op => op.value === disapprovalReason
                    )}
                    onChange={async disapprovalReason => {
                      await updateJobMutation({
                        variables: {
                          id,
                          input: {
                            disapprovalReason:
                              disapprovalReason?.value as string,
                          },
                        },
                      });
                    }}
                  />
                  <Separator />
                  <AdminsOnly>
                    <Label>Admins only</Label>
                    <FieldWithButton>
                      <TextField
                        label="Original URL"
                        autoSet={autoSetFields.has("original_url")}
                        value={actualOriginalUrl}
                        mutation={UpdateJobOriginalUrlDocument}
                        fieldName="originalUrl"
                        id={id}
                        data-testid="original-url"
                        disabled={!editOriginalUrl}
                      />
                      <Button
                        level="secondary"
                        size="small"
                        onClick={() => setEditOriginalUrl(!editOriginalUrl)}
                      >
                        Edit
                      </Button>
                    </FieldWithButton>
                    <CreateNewJobTextItemField
                      jobId={id}
                      label="Create new technology"
                      create={CreateTechnologyUsedDocument}
                    />
                    <CreateNewJobTextItemField
                      jobId={id}
                      label="Create new language requirement"
                      create={CreateLanguageRequirementDocument}
                    />

                    <FieldLabel
                      text={"Other jobs at " + data?.job.company.name}
                    >
                      <JobsAtSameCompany jobId={id} />
                    </FieldLabel>

                    {company.teams.length > 0 && (
                      <>
                        <Label>Team details</Label>
                        <Text size={-1} bold as="div">
                          Team:
                          <TeamSelect
                            key={team?.id}
                            teams={company.teams as CompanyTeam[]}
                            members={teamMembers as JobCompanyTeamMember[]}
                            teamId={team?.id}
                            handleUpdate={async (
                              teamId: string | null = null,
                              teamMembers: JobCompanyTeamMembersInput[]
                            ) => {
                              await updateJobMutation({
                                variables: {
                                  id,
                                  input: {
                                    companyTeamId: teamId,
                                    jobCompanyTeamMembers: teamMembers,
                                  },
                                },
                              });
                            }}
                          />
                        </Text>
                      </>
                    )}
                  </AdminsOnly>
                </Spacing>
              </ColumnWrapper>
            </Spacing>
          </InnerContent>
        </JobWrapper>
        <JobIframe
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          src={previewUrl ?? ""}
        />
      </Container>
    </Wrapper>
  );
}
